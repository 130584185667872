<script>
  import { Link } from "svelte-routing";

  let lastUpdated = "3d ago";
  let time = "";
  let timeZone = "";

  const updateTime = () => {
    const now = new Date();

    const options = {
      timeZone: "Europe/Amsterdam",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZoneName: "short"
    };

    const timeWithZone = now.toLocaleString("en-US", options);

    const [timePart, zonePart] = timeWithZone.split(" ");

    time = timePart;
    timeZone = zonePart;
  };

  setInterval(updateTime, 1000);
  updateTime();
</script>

<main>
  <div class="container">
    <div class="vertical-line-left"></div>
    <div class="horizontal-line"></div>
  </div>
  <header>
    <div class="menu">
      <!-- svelte-ignore missing-declaration -->
      <img src="/img/logo_10110.svg" alt="Logo 10110" class="logo" />
    </div>

    <div class="title">
      <div class="desc">
        <h1 class="name">In a world full of bugs, be the patch.</h1>
        <time class="status-time monospace"> {lastUpdated} </time>
      </div>
      <p class="pronouns">[He/Him/His]</p>
    </div>
  </header>
  <section>
    <p class="intro">
      Welcome! If you're here, chances are you're ready to elevate your online
      presence — and you're in the right place. This domain was created with one
      clear mission: to help Persian art and culture leave a lasting mark on the
      digital landscape.
    </p>

    <p class="intro">
      I'm a software designer and engineer, specializing in creating simple,
      impactful websites. As a member of the <a
        href="https://make.wordpress.org/core/"
        target="_blank">WordPress core team</a
      >
      and the
      <a href="https://infrequently.org/stickers/"
        >Anti JavaScript JavaScript Club</a
      >, I balance coding with teaching at Fontys University of Applied Sciences
      in Eindhoven and sharing insights about the web. I'm passionate about the
      open web, web standards, and accessibility.
    </p>
    <div class="code-container">
      <p>My goal is simple: to build a web that works for everyone.</p>
    </div>

    <p>
      Currently based in The Netherlands where it's <span
        class="time"
        data-timestamp-text=", where it's currently {time}"
      >
        {time.slice(0, 2)}<span class="blink">:</span>{time.slice(3)}
        {timeZone}.
      </span>
    </p>

    <div class="code-container">
      <p>
        Whether you're an artist, filmmaker, writer, or someone who appreciates
        cultural history, I'm here to help you showcase your passion. Through
        custom web design and creating digital spaces that bring your work to
        life, I’m committed to ensuring that your contribution is not only
        remembered but also respected worldwide.
      </p>
    </div>

    <p>
      Together, we can make Persian art and culture an integral part of the
      digital world. Ready to blend the beauty of history with the future? Let’s
      create something unforgettable.
    </p>
    <p>
      For work-related inquiries, contact me at <code
        >&lt;info at 10110 dot dev&gt;</code
      >.
    </p>
  </section>

  <div class="version">
    <a href="https://10110.dev" target="_blank">v.1.0</a>
  </div>
</main>

<style>
  :global(body) {
    margin: 0;
    padding: 0;
    background-color: var(--backgroundColor);
    color: var(--textColor);
    font-size: 1.2em;
    font-family: var(--sansSerif);
    line-height: 1.62em;
    word-wrap: break-word;
  }

  header {
    margin-bottom: 3rem;
  }

  .menu {
    display: flex;
    justify-content: space-between;
  }

  .guide {
    width: 2rem;
    margin-bottom: 1rem;
  }

  main {
    max-width: 45vw;
    margin: 50px auto 0 auto;
  }

  section {
    margin-top: 20px;
  }

  code {
    color: var(--activeColor);
  }

  .title {
    display: flex;
    justify-content: space-between;
  }

  .title p {
    color: var(--textColor);
    font-size: 0.75rem;
    font-weight: 400;
    margin: 0;
  }

  .name {
    color: var(--mutedTextColor);
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
  }

  .intro {
    color: var(--textColor);
    margin: 0 0 10px 0;
  }

  .logo {
    width: 10rem;
    margin-bottom: 1rem;
  }

  .desc {
    display: flex;
    justify-content: space-between;
    font-size: 0.75em;
    line-height: 1.5;
    color: var(--textColor);
  }

  time.monospace {
    font-size: 0.7em;
    color: var(--mutedTextColor);
  }

  .time {
    color: var(--activeColor);
  }

  .blink {
    animation: blink 2s step-start 0s infinite;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }

  .status-time {
    margin-left: 0.7em;
  }

  .container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }

  .vertical-line-left {
    position: relative;
    top: 0;
    left: 25vw;
    width: 1px;
    height: 100%;
    background-color: var(--lineColor);
    z-index: -1;
  }

  .horizontal-line {
    position: absolute;
    top: 18vh;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--lineColor);
    z-index: -1;
  }

  .code-container {
    display: flex;
    max-width: 45vw;
    padding: 0 20px;
    margin: 20px auto;
    background-color: var(--frameBackgroundColor);
    position: relative;
    overflow: hidden;
    border-radius: 20px;
  }

  .code-container {
    font-family: var(--loRes);
    font-weight: 400;
  }

  code {
    font-family: var(--loRes);
    font-weight: 400;
  }

  .version {
    text-align: right;
    font-size: 0.6em;
  }

  .version a {
    font-size: 0.6rem;
    color: var(--textColor);
    text-decoration: none;
    background-color: #2d3947;
    padding: 3px 7px;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    main {
      max-width: 80vw;
      margin: 50px auto;
    }
    .code-container {
      max-width: 80vw;
    }
    .vertical-line-left {
      left: 6vw;
    }
    .horizontal-line {
      top: 18vh;
    }
    .desc {
      flex-direction: column;
    }
    .status-time {
      margin-left: 0;
      color: var(--activeColor) !important;
    }
    .pronouns {
      display: none;
    }
  }
</style>
